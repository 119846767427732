import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Banner} from "../model/banner.model";

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  apiUrl = environment.apiURL

  constructor(private http: HttpClient) {
  }

  getBanner() {
    return this.http.get<Banner>(`${this.apiUrl}/banners`)
  }
}
