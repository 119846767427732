import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdmissionService} from "../../services/admission.service";
import {Contact} from "../../model/contact.model";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit, OnDestroy {
  contacts: Contact = {}
  subscription: Subscription = new Subscription();

  constructor(private admissionService: AdmissionService) {
  }

  ngOnInit() {
    this.subscription = this.admissionService.getContacts().subscribe(
      (contactsData: Contact) => {
        this.contacts = contactsData
      }
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
