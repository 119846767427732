import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Exam} from "../model/exam.model";
import {Contact} from "../model/contact.model";
import {Inquiry} from "../model/inquiry.model";
import {Rating} from "../model/rating.model";

@Injectable({
  providedIn: 'root'
})
export class AdmissionService {
  apiUrl = environment.apiURL

  constructor(private http: HttpClient) {
  }

  getIndividualAdmission(reqData: { identifier: string; identifierValue: string; exam_code: string }) {
    return this.http.post(`${this.apiUrl}/individuals`, reqData, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
  }

  getSchoolAdmissions(reqData: any) {
    return this.http.post(`${this.apiUrl}/schools/admissions`, reqData, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
  }

  getSchoolRegister(reqData: any) {
    return this.http.post(`${this.apiUrl}/schools/register`, reqData, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
  }

  getActiveSchoolAdmissionExams() {
    return this.http.get<Exam[]>(`${this.apiUrl}/exams/school/admission/active`)
  }

  getTestSchoolAdmissionExams() {
    return this.http.get<Exam[]>(`${this.apiUrl}/exams/school/admission/testing`)
  }

  getActiveSchoolRegisterExams() {
    return this.http.get<Exam[]>(`${this.apiUrl}/exams/school/register/active`)
  }

  getTestSchoolRegisterExams() {
    return this.http.get<Exam[]>(`${this.apiUrl}/exams/school/register/testing`)
  }

  getAllActiveExams() {
    return this.http.get<Exam[]>(`${this.apiUrl}/exams/all/admission/active`)
  }

  getAllTestAdmissionExams() {
    return this.http.get<Exam[]>(`${this.apiUrl}/exams/all/admission/testing`)
  }

  getContacts() {
    return this.http.get<Contact>(`${this.apiUrl}/inquiries/contacts`)
  }

  setInquiry(inquiryData: Inquiry) {
    return this.http.post<Inquiry>(`${this.apiUrl}/inquiries/add`, inquiryData)
  }

  setRating(ratingData: Rating) {
    return this.http.post<Rating>(`${this.apiUrl}/ratings/add`, ratingData)
  }
}
