import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {PagenotfoundComponent} from "./pagenotfound/pagenotfound.component";

const routes: Routes = [
  {path: '', component: HomeComponent},
  {
    path: 'individuals',
    loadChildren: () => import('./individuals/individuals.module').then(m => m.IndividualsModule)
  },
  {
    path: 'principals',
    loadChildren: () => import('./school-principals/school-principals.module').then(m => m.SchoolPrincipalsModule)
  },
  {
    path: 'inquiries',
    loadChildren: () => import('./inquiries/inquiries.module').then(m => m.InquiriesModule)
  },
  {
    path: 'samples',
    loadChildren: () => import('./samples/sample-admissions.module').then(m => m.SampleAdmissionsModule)
  },
  {path: '**', pathMatch: 'full', component: PagenotfoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
