<div class="text-center pt-12 pb-6 text-gray-500 text-sm">
  <div *ngIf="contacts.show_inquiry || contacts.telephone_status || contacts.email_status">Are you facing any difficulties?</div>
  <div class="my-3" *ngIf="contacts.show_inquiry">
    <button
      routerLink="inquiries/add"
      pButton
      type="button"
      label="Make an Inquiry"
      class="p-button-sm text-sm p-button-raised p-button-text"
      pRipple>
    </button>
  </div>
  <div *ngIf="contacts.email_status || contacts.telephone_status">
    <div>or</div>
    Do not hesitate to
    <span *ngIf="contacts.telephone_status">call us on {{contacts.telephone}}</span>
    <span *ngIf="contacts.email_status && contacts.telephone_status"> or </span>
    <span *ngIf="contacts.email_status">email us at
    <a [href]="contacts.email_link" class="contact-email" target="_blank">{{contacts.email}}</a>
    </span>
  </div>
</div>
