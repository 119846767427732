<div *ngIf="banner?.display" class="bg-gray-700 text-gray-100 py-1 px-2 flex justify-center flex-wrap">
  <div class="vertically-center space-x-2 md:space-x-4">
    <span class="text-sm md:text-base text-center">{{banner.message}}</span>
    <a pRipple (click)="onClose()"
       class="vertically-center no-underline rounded-full justify-center text-gray-50 hover:bg-gray-600 cursor-pointer"
       style="width:1.6rem; height: 1.6rem">
      <i class="pi pi-times"></i>
    </a>
  </div>
</div>
