<div class="flex justify-center">
  <div class="grid grid-cols-1 lg:grid-cols-2 space-y-4 md:space-y-0">
    <button routerLink="/individuals" pButton pRipple type="button"
            class="home-btn home-btn-sch max-w-xs shadow-md h-40 md:h-44 w-80">
      <div class="mx-auto">
        <i class="pi pi-user" style="font-size: 2rem"></i>
        <div class="text-lg">Individual Admission Card</div>
        <div class="text-sm">(For Exam Candidates Only)</div>
      </div>
    </button>
    <button routerLink="/principals" pButton pRipple type="button"
            class="home-btn home-btn-other max-w-xs shadow-md h-40 md:h-44 w-80">
      <div class="mx-auto">
        <i class="pi pi-users" style="font-size: 2rem"></i>
        <div class="text-lg">Admission Cards, School Register</div>
        <div class="text-sm">(For Institute Principals Only)</div>
      </div>
    </button>
  </div>
</div>
