import {Injectable} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../store/app.state";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {Signin} from "../model/signin.model";
import {setSignedInStatus} from "../store/shared/shared.actions";
import {getSignedInStatus} from "../store/shared/shared.selectors";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiUrl = environment.apiURL

  constructor(private store: Store<AppState>, private http: HttpClient, private router: Router) {
  }

  signIn(signInData: Signin) {
    return this.http.post(`${this.apiUrl}/auth/signin`, signInData)
  }

  isTokenValid(token: any) {
    return this.http.post(`${this.apiUrl}/auth/token-validity`, {token})
  }

  setSignedInStatus() {
    if (!localStorage.getItem('token')) {
      this.store.dispatch(setSignedInStatus({status: false}))
    } else {
      this.http.post(`${this.apiUrl}/auth/token-validity`, {token: localStorage.getItem('token')}).subscribe((response: any) => {
        this.store.dispatch(setSignedInStatus({status: response.status}))
      })
    }
  }

  getSignInStatus() {
    return this.store.select(getSignedInStatus)
  }

  signOut() {
    localStorage.removeItem('token');
    this.store.dispatch(setSignedInStatus({status: false}))
    this.router.navigate(['samples', 'signin']);
  }
}
