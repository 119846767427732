<section class="bg-white">
  <div class="pb-12 px-4 mx-auto max-w-screen-xl lg:px-6">
    <div class="mx-auto max-w-screen-sm text-center space-y-4 md:space-y-8">
      <h1 class="text-7xl tracking-tight font-extrabold lg:text-9xl text-indigo-600">404</h1>
      <p class="text-xl tracking-tight font-semibold text-gray-900 md:text-3xl">Whoops! That page doesn’t exist.</p>
      <button
        pButton
        routerLink="/"
        type="submit"
        label="Back to Home Page"
        class="p-button-sm text-sm p-button-raised"
        pRipple>
      </button>
    </div>
  </div>
</section>
