export interface SharedState {
  showLoading: boolean
  errorMessage: string
  isSignedIn: boolean
}

export const initialState: SharedState = {
  showLoading: false,
  errorMessage: '',
  isSignedIn: false,
}
