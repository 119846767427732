<app-spinner *ngIf="showLoading | async"></app-spinner>
<app-banner></app-banner>
<app-header class="sticky top-0 z-50"></app-header>
<div>
  <app-heading></app-heading>
  <router-outlet></router-outlet>
  <app-contact></app-contact>
</div>
<app-footer></app-footer>
<p-scrollTop [threshold]="200" [style]="{ background: '#3F51B5' }"></p-scrollTop>
