<footer class="p-4 bg-white sm:p-6">
  <div class="md:flex md:justify-between">
    <div class="mb-6 md:mb-0">
      <span class="flex items-center justify-center mb-4">
        <img src="assets/gov-logo.png" class="h-20" alt="Government Logo"/>
      </span>
      <span class="flex justify-center font-semibold whitespace-nowrap">
        Department of Examinations, Sri Lanka</span>
    </div>
    <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
      <div>
        <h2 class="mb-4 text-sm font-semibold text-gray-900 uppercase">Quick links</h2>
        <ul class="text-gray-600 text-sm">
          <li class="mb-2">
            <a href="https://doenets.lk/examresults" target="_blank" class="hover:underline">Exam Results</a>
          </li>
          <li class="mb-2">
            <a href="https://doenets.lk/examcalendar" target="_blank" class="hover:underline">Exam Calender</a>
          </li>
          <li>
            <a href="https://certificate.doenets.lk/" target="_blank" class="hover:underline">Certificates</a>
          </li>
        </ul>
      </div>
      <div>
        <h2 class="mb-4 text-sm font-semibold text-gray-900 uppercase">Other links</h2>
        <ul class="text-gray-600 text-sm">
          <li class="mb-2"><a href="https://moe.gov.lk/" target="_blank" class="hover:underline">Ministry of
            Education</a></li>
          <li class="mb-2"><a href="https://www.edupub.gov.lk/" target="_blank" class="hover:underline">Edu. Publications
            Dep.</a></li>
          <li class="mb-2"><a href="https://www.ugc.ac.lk/" target="_blank" class="hover:underline">UGC</a></li>
        </ul>
      </div>
      <div>
        <h2 class="mb-4 text-sm font-semibold text-gray-900 uppercase">Follow us</h2>
        <ul class="text-gray-600 text-sm">
          <li class="mb-2">
            <a href="https://www.facebook.com/doe.gov.lk/" target="_blank" class="hover:underline">Facebook</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <hr class="my-6 border-gray-200 mx-auto lg:my-4"/>
  <div class="flex items-center justify-center">
    <span class="text-sm text-gray-500 text-center">© {{currentYear}}
      <a href="https://doenets.lk" class="hover:underline">Department of Examinations, Sri Lanka</a>. All Rights Reserved.
    <span>(version 3.0.3)</span>
    </span>
  </div>
</footer>
