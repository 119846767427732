import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AppRoutingModule} from './app-routing.module';
import {StoreModule} from '@ngrx/store';
import {HttpClientModule} from "@angular/common/http";
import {NgHcaptchaModule} from "ng-hcaptcha";
import {appReducer} from "./store/app.state";

import {AppComponent} from './app.component';
import {BannerComponent} from './shared/banner/banner.component';
import {HeaderComponent} from './shared/header/header.component';
import {HeadingComponent} from './shared/heading/heading.component';
import {HomeComponent} from './home/home.component';
import {ContactComponent} from './shared/contact/contact.component';
import {PagenotfoundComponent} from './pagenotfound/pagenotfound.component';
import {FooterComponent} from './shared/footer/footer.component';

import {RippleModule} from "primeng/ripple";
import {ScrollTopModule} from "primeng/scrolltop";
import {SpinnerComponent} from './shared/spinner/spinner.component';
import {TooltipModule} from "primeng/tooltip";
import {ButtonModule} from "primeng/button";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SpinnerComponent,
    HomeComponent,
    PagenotfoundComponent,
    ContactComponent,
    HeadingComponent,
    BannerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ScrollTopModule,
    RippleModule,
    StoreModule.forRoot(appReducer),
    NgHcaptchaModule.forRoot(),
    TooltipModule,
    ButtonModule,
  ],
  providers: [],
  exports: [ContactComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
}
