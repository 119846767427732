import {Component, OnDestroy, OnInit} from '@angular/core';
import {Banner} from "../../model/banner.model";
import {BannerService} from "../../services/banner.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit, OnDestroy {
  banner: Banner = {message: '', display: false};
  subscription: Subscription = new Subscription();

  constructor(private bannerService: BannerService) {
  }

  ngOnInit() {
    this.subscription = this.bannerService.getBanner().subscribe((data: Banner) => {
      this.banner = data
    })
  }

  onClose() {
    this.banner.display = false
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
