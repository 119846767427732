import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {Store} from "@ngrx/store";
import {AppState} from "./store/app.state";
import {Observable} from "rxjs";
import {getLoading, getSignedInStatus} from "./store/shared/shared.selectors";
import {setLoadingSpinner} from "./store/shared/shared.actions";
import {AuthService} from "./services/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  showLoading: Observable<boolean> = new Observable<boolean>()

  constructor(private primengConfig: PrimeNGConfig,
              private store: Store<AppState>,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.store.dispatch(setLoadingSpinner({status: true}))
    this.primengConfig.ripple = true;
    this.showLoading = this.store.select(getLoading)
    this.authService.setSignedInStatus()
    this.store.dispatch(setLoadingSpinner({status: false}))
  }

}
